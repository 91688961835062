<template>
  <div>
    <button
      class="pull-right btn btn-icon btn-circle btn-light"
      @click="fetchData()"
    >
      <i v-if="this.loading" class="fa fa-sync fa-spin"></i>
      <i v-else class="fa fa-sync"></i>
    </button>
    <h1 class="page-header">Dashboard <small></small></h1>

    <div class="row"></div>
    <div class="row">
      <div class="col-md-3">
        <div class="widget widget-stats bg-info">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Jumlah Pasien</div>
            <div class="stats-number">
              {{ numberFormat(jumlah_pasien) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget widget-stats bg-primary">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Kunjungan Hari Ini</div>
            <div class="stats-number">
              {{ numberFormat(kunjungan_hari) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget widget-stats bg-warning">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Kunjungan Bulan Ini</div>
            <div class="stats-number">
              {{ numberFormat(kunjungan_bulan) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget widget-stats bg-danger">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">Kunjungan Tahun Ini</div>
            <div class="stats-number">
              {{ numberFormat(kunjungan_tahun) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="row">
          <div class="col-md-12">
            <div class="widget widget-stats bg-purple">
              <div class="stats-icon stats-icon-lg">
                <i class="fa fa-building fa-fw"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Puskesmas Pembantu</div>
                <div class="stats-number">
                  {{ numberFormat(jumlah_pustu) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="widget widget-stats bg-pink">
              <div class="stats-icon stats-icon-lg">
                <i class="fa fa-building fa-fw"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Posyandu</div>
                <div class="stats-number">
                  {{ numberFormat(jumlah_posyandu) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel">
          <div class="panel-heading p-5">
            <h6 class="panel-title text-center">Demography Pasien</h6>
          </div>
          <div class="panel-body">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-6 p-3">
                <div class="text-center">
                  <span><i class="fa fa-male fa-3x text-blue"></i></span>
                  <br />
                  <span>{{
                    numberFormat(jenis_kelamin.laki_laki, jumlah_pasien)
                  }}</span>
                  <br />
                  <span class="text-blue">
                    {{ persentse(jenis_kelamin.laki_laki, jumlah_pasien) }}%
                  </span>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="text-center">
                  <span><i class="fa fa-female fa-3x text-pink"></i></span>
                  <br />
                  <span>{{ numberFormat(jenis_kelamin.perempuan) }}</span>
                  <br />
                  <span class="text-pink">
                    {{ persentse(jenis_kelamin.perempuan, jumlah_pasien) }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="panel p-3" ref="barRuang"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingExcel from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import seriesLabel from "highcharts/modules/series-label";

// Inisialisasi modul Highcharts
drilldown(Highcharts);
exporting(Highcharts);
exportingExcel(Highcharts);
accessibility(Highcharts);
seriesLabel(Highcharts);

export default {
  mixins: [sipData],
  name: "DashboardPage",
  data() {
    return {
      jumlah_pasien: 0,
      kunjungan_hari: 0,
      kunjungan_bulan: 0,
      kunjungan_tahun: 0,
      jumlah_pustu: 0,
      jumlah_posyandu: 0,
      kunjungan_ruang: [],
      jenis_kelamin: "",
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      axios
        .get("/v1/dashboard/", {
          headers: {
            "X-Token": this.userToken,
          },
        })
        .then((response) => {
          this.kunjungan_hari = response.data.kunjungan_hari;
          this.kunjungan_bulan = response.data.kunjungan_bulan;
          this.kunjungan_tahun = response.data.kunjungan_tahun;
          this.jumlah_pasien = response.data.jumlah_pasien;
          this.jumlah_pustu = response.data.jumlah_pustu;
          this.jumlah_posyandu = response.data.jumlah_posyandu;
          this.kunjungan_ruang = response.data.kunjungan_ruang;
          this.jenis_kelamin = response.data.jenis_kelamin;
          this.renderBarRuang();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderBarRuang() {
      Highcharts.chart(this.$refs.barRuang, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Grafik Kunjungan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Kunjungan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Jumlah Kunjungan : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.kunjungan_ruang.map((data) => ({
              name: data.nama_pemeriksaan,
              y: parseInt(data.j_kunjungan),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    persentse(paramA, paramB) {
      const a = parseInt(paramA);
      const b = parseInt(paramB);
      const c = (a / b) * 100;
      return c.toFixed(2);
    },
    numberFormat(value) {
      if (!value) return "0";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>