import axios from "axios";
import Swal from "sweetalert2";
// import router from "@/router";

const baseURL = process.env.VUE_APP_BASE_URL;
const username = process.env.VUE_APP_USERNAME;
const password = process.env.VUE_APP_PASSWORD;
const account = btoa(`${username}:${password}`);

axios.interceptors.request.use((config) => {
  config.baseURL = baseURL;

  config.headers.Authorization = `Basic ${account}`;
  config.headers.versi = process.env.VUE_APP_VERSI;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status === 401) {
      Swal.fire({
        title: "Info",
        text: "Session Habis, Silahkan Login Kembali",
        icon: "info",
        showCancelButton: false,
        showConfirmButton: true,
      }).then((result) => {
        if (result.value) {
          localStorage.removeItem("sipData");
          // router.push('/logout');
        }
      });
    } else if (status == 426) {
      Swal.fire({
        title: "Info",
        text: "Versi Baru Tersedia, Refresh Halaman",
        icon: "info",
        showCancelButton: false,
        showConfirmButton: false,
      });
      setTimeout(() => {
        Swal.close();
        caches
          .keys()
          .then(function (names) {
            for (let name of names) {
              caches.delete(name);
            }
          })
          .then(() => {
            window.location.reload();
          });
      }, 3000);
    } else if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
