import { jwtDecode } from "jwt-decode";

const sipData = {
  data() {
    return {
      sipData: null,
      userFullName: null,
      userId: null,
      userName: null,
      userEmail: null,
      userLevel: null,
      userAccess: null,
      userToken: null,
      userFaskes: null,
      userPhoto: "../assets/img/user.png",
    };
  },
  created() {
    const sipData = localStorage.getItem("sipData");
    if (sipData) {
      this.sipData = JSON.parse(sipData);
      this.userFullName = this.sipData.nama;
      this.userPhoto = this.sipData.image_profil;
      const decodedToken = jwtDecode(this.sipData.token);
      this.userId = decodedToken.id;
      this.userName = decodedToken.username;
      this.userLevel = decodedToken.level;
      this.userAccess = decodedToken.access_rights;
      this.userFaskes = decodedToken.faskes;
      this.userToken = this.sipData.token;
      // this.userPhoto = this.sipData.foto;
    }
  },
};

export default sipData;
