import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  {
    path: "*",
    component: () => import("../pages/NotFound.vue"),
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/jenis-layanan/",
    component: () => import("../pages/jenis-layanan/Blanko.vue"),
  },
  {
    path: "/jenis-layanan/create",
    component: () => import("../pages/jenis-layanan/Form.vue"),
  },
  {
    path: "/jenis-layanan/update/:id",
    component: () => import("../pages/jenis-layanan/Update.vue"),
  },
  {
    path: "/pustu/",
    component: () => import("../pages/pustu/List.vue"),
  },
  {
    path: "/pustu/create",
    component: () => import("../pages/pustu/Form.vue"),
  },
  {
    path: "/pustu/update/:id",
    component: () => import("../pages/pustu/Update.vue"),
  },
  {
    path: "/posyandu/",
    component: () => import("../pages/posyandu/List.vue"),
  },
  {
    path: "/posyandu/create",
    component: () => import("../pages/posyandu/Form.vue"),
  },
  {
    path: "/posyandu/update/:id",
    component: () => import("../pages/posyandu/Update.vue"),
  },
  {
    path: "/rumah-sakit/create",
    component: () => import("../pages/rumah-sakit/Form.vue"),
  },
  {
    path: "/rumah-sakit/update/:id",
    component: () => import("../pages/rumah-sakit/Update.vue"),
  },
  {
    path: "/rumah-sakit/detail/:id",
    component: () => import("../pages/rumah-sakit/Detail.vue"),
  },
  {
    path: "/rumah-sakit/",
    component: () => import("../pages/rumah-sakit/List.vue"),
  },
  {
    path: "/pasien/",
    component: () => import("../pages/pasien/List.vue"),
  },
  {
    path: "/pasien/create/",
    component: () => import("../pages/pasien/Form.vue"),
  },
  {
    path: "/pasien/detail/:id",
    component: () => import("../pages/pasien/Detail.vue"),
  },
  {
    path: "/pasien/update/:id",
    component: () => import("../pages/pasien/Update.vue"),
  },
  {
    path: "/aduan-layanan/",
    component: () => import("../pages/aduan-layanan/List.vue"),
  },
  {
    path: "/tarif-layanan/",
    component: () => import("../pages/tarif-layanan/List.vue"),
  },
  {
    path: "/tarif-layanan/create/",
    component: () => import("../pages/tarif-layanan/Form.vue"),
  },
  {
    path: "/tarif-layanan/update/:id",
    component: () => import("../pages/tarif-layanan/Update.vue"),
  },
  {
    path: "/klinik/",
    component: () => import("../pages/klinik/List.vue"),
  },
  {
    path: "/klinik/detail/:id",
    component: () => import("../pages/klinik/Detail.vue"),
  },
  {
    path: "/klinik/create/",
    component: () => import("../pages/klinik/Form.vue"),
  },
  {
    path: "/klinik/update/:id",
    component: () => import("../pages/klinik/Update.vue"),
  },
  {
    path: "/antrean",
    component: () => import("../pages/antrean/List.vue"),
  },
  {
    path: "/kunjungan",
    component: () => import("../pages/kunjungan/List.vue"),
  },
  {
    path: "/kunjungan/detail/:id",
    component: () => import("../pages/kunjungan/Detail.vue"),
  },
  {
    path: "/ruang-pelayanan",
    component: () => import("../pages/ruang-pelayanan/List.vue"),
  },
  {
    path: "/ruang-pelayanan/create",
    component: () => import("../pages/ruang-pelayanan/Form.vue"),
  },
  {
    path: "/ruang-pelayanan/update/:id",
    component: () => import("../pages/ruang-pelayanan/Update.vue"),
  },
  {
    path: "/profil",
    component: () => import("../pages/profil/Profil.vue"),
  },
  {
    path: "/pengguna",
    component: () => import("../pages/users/List.vue"),
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/users/Form.vue"),
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/users/Edit.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/eregister/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
