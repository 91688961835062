<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-home f-s-20", title: "Dashboard" },
  {
    path: "/antrean",
    icon: "fa fa-wheelchair f-s-20",
    title: "Antrean",
  },
  {
    path: "/kunjungan",
    icon: "fa fa-history f-s-20",
    title: "Riwayat Kunjungan",
  },
  {
    path: "/pasien",
    icon: "fa fa-users f-s-20",
    title: "Pasien",
  },
  // {
  //   path: "/aduan-layanan",
  //   icon: "fa fa-frown f-s-20",
  //   title: "Aduan Layanan",
  // },
  {
    path: "/pustu",
    icon: "fa fa-building f-s-20",
    title: "Puskesmas Pembantu",
  },
  {
    path: "/posyandu",
    icon: "fa fa-building f-s-20",
    title: "Posyandu",
  },
  {
    path: "/rumah-sakit",
    icon: "fa fa-building f-s-20",
    title: "Rumah Sakit",
  },
  {
    path: "/klinik",
    icon: "fa fa-hospital f-s-20",
    title: "Klinik",
  },
  {
    path: "/tarif-layanan",
    icon: "fa fa-money-bill-alt f-s-20",
    title: "Tarif Jasa Layanan",
  },
  {
    path: "/ruang-pelayanan",
    icon: "fa fa-medkit f-s-20",
    title: "Ruang Pelayanan",
  },
  {
    path: "/jenis-layanan",
    icon: "fa fa-medkit f-s-20",
    title: "Jenis Pelayanan",
  },
  {
    path: "/pengguna",
    icon: "fa fa-users f-s-20",
    title: "Pengguna",
  },
];

export default sidebarMenu;
</script>
